.row {
  display: table-row;

  font-size: var(--font-base-size-lg);

  cursor: pointer;

  .cell {
    display: table-cell;
    height: 50px;

    vertical-align: middle;

    &:first-child {
      padding-left: 16px;
    }
  }

  .zoneRating {
    padding-right: 6px;
    font-size: var(--font-secondary-size-md);
    color: var(--grey-500);
  }

  .zoneBaseInfoContainer {
    display: flex;
    align-items: center;
  }

  .zoneContainer {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  .disclaimer {
    margin-left: 8px;
  }

  &:hover {
    background-color: var(--black-600);
  }
}

.tooltipContainer {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-left: 8px;

  padding: 4px;

  .tooltip {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    padding: 8px 12px;
    background: var(--black-700);
    color: var(--white);
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    transition: opacity 0.2s ease;
    z-index: 1000;
    pointer-events: none;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: var(--black-700) transparent transparent transparent;
    }
  }

  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
}

.disclaimerContainer {
  display: flex;
  align-items: center;
  margin-left: 8px;
  gap: 4px;
}

.zoneNameContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.icsBadge {
  width: 16px;
  height: 16px;
  color: var(--primary-color);
  vertical-align: middle;
  cursor: help;
}
